<template>
    <div style="height:400px">
      <card style="border-radius: 20px" class="shadow card card-feature" @mouseenter="onhover()" @mouseleave="onleave()"  
        ><img
          :src="data.procat_image"
          style="border-radius: 20px; height: 200px; object-fit: cover"
          class="w-100"
        />
        <div
          style="height: 80px"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <h2 class="mb-0 text-center my-2 h3" style="color: #0B5087; font-size: 16px">
            {{ data.procat_name }}
          </h2>
        </div>
        <router-link class="mx-auto" :to="data.procat_redirect ? data.procat_redirect : `/product-services/${data.procat_layout_type}/${data.procat_id}`" >
          <div
            :id="`btn-feature${data.procat_id}`"
            style="background: #1877f2; color: white !important; margin-top: -4em; opacity: 0"
            class="btn-feature btn mb-2"
          >
            Features And Advantages
          </div>
        </router-link>
      </card>
    </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onhover() {
      anime({
        targets: `#btn-feature${this.data.procat_id}`,
        keyframes: [
          { marginTop: "-4em", opacity: "0" },
          { marginTop: "0em", opacity: "1" },
        ],
        easing: "easeOutElastic(1, .8)",
        duration: 800,
      });
    },
    onleave() {
      anime({
        targets: `#btn-feature${this.data.procat_id}`,
        keyframes: [
          { marginTop: "0em", opacity: "1" },
          { marginTop: "-4em", opacity: "0" },
        ],
        easing: "easeOutElastic(1, .8)",
        duration: 800,
      });
    },
  },
};
</script>
