<template>
  <Layout navbarTransparent>
    <Header :image="current.procat_image" :header="current.procat_desc" :header_eng="current.procat_desc_en" />


    <div class="my-4 d-flex flex-column align-items-center">
      <h2 class="font-weight-bolder">
        <div v-if="['Trading & Distribution', 'Manufacturing', 'Retail'].includes(current.procat_name)" class="text-center">
          ERP System
        </div>
        {{['Trading & Distribution', 'Manufacturing', 'Retail'].includes(current.procat_name) ? 'Modul' : ''}} {{ current.procat_name }}
      </h2>
      <img src="@/static/assets/Group 676.png" />
    </div>

    <div class="container">
      <div class="position-relative">
        <div class="px-5">
          <swiper
            class="swiper-multiple"
            :options="swiperOptions"
            ref="swiper"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in swiperData" :key="index">
              <cardfeature :data="data" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="navigation" style="margin-top:-9em">
          <div slot="button-next" class="swiper-button-next" id="swiper-next">
            <div class="rounded-circle d-flex text-white" style="width:50px;height:50px;background:#0F6BB4">
              <i class="bx bx-chevron-right m-auto" style="font-size:50px"></i>
            </div>
          </div>
          <div slot="button-prev" class="swiper-button-prev" id="swiper-prev">
            <div class="rounded-circle d-flex text-white" style="width:50px;height:50px;background:#0F6BB4">
              <i class="bx bx-chevron-left m-auto" style="font-size:50px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img src="@/static/assets/Group 683.png" class="w-100" style="margin-top: -20%" />

    <TradeDistribute v-if="current.procat_name == 'Trading & Distribution'" />
    <Manufacture v-else-if="current.procat_name == 'Manufacturing'" />
    <Retail v-else-if="current.procat_name == 'Retail'" />
    
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Header from "./products/header.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import TradeDistribute from './product_service/workflow/tradingdistribution.vue'
import Retail from './product_service/workflow/retail.vue'
import Manufacture from './product_service/workflow/manufacture.vue'



import cardfeature from './products/components/card-feature.vue'

import store from "@/store";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    TradeDistribute, Manufacture, Retail, cardfeature,

    Header,
    Swiper,
    SwiperSlide,
    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
  },
  methods: {
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
  },
  async mounted() {
    this.swiperData = await store.dispatch("product_categories/getDataList", {
      level: 3,
      parent: this.$route.params.id,
    });
    this.current = await store.dispatch("product_categories/getDataList", {
      id: this.$route.params.id,
    }); 

  },
  data() {
    return {
      current: {},
      swiperData: [],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        watchOverflow: true,
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style>
.item-list-custom:hover {
  background: #E8F1F8; cursor: pointer;
}

.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}


#swiper-next::after {
  content: 'asdqsd';
}
#swiper-prev::after {
  content: 'asdqsd';
}
</style>
