<template>
  <div>
    <div class="text-center">
      <h1 class="font-weight-bolder">Workflow</h1>
      <h1 class="font-weight-bolder" style="color: #0b5087">
        Modul Trading & Distribution
      </h1>
      <img src="@/static/assets/Group 676.png" />
    </div>

    <div style="background: #0b5087; border-radius: 20px" class="p-4 m-5">
      <div class="row align-items-center">
        <div class="col-lg-auto w-100" style="max-width:300px">
          <div class="d-flex flex-column p-1 px-1 bg-white" style="border-radius: 20px;height:550px">
            <span>
              <h6
                v-for="(item, idx) in options"
                :key="item + idx"
                class="item-list-custom mb-0 rounded-lg font-weight-bolder"
                style="padding: 0.3em 1em"
              >
                {{ !firstActive ? (firstOption.length + idx + 1) : idx + 1 }}. {{ item }}
              </h6>
            </span>
            <div class="d-flex justify-content-center mt-auto" style="gap: 10px">
              <div
                :style="`width:12px;height:12px;cursor:pointer;background:${
                  firstActive ? '#0B5087' : '#B8B9B9'
                }`"
                @click="firstActive = true"
                class="rounded-circle"
              />
              <div
                :style="`width:12px;height:12px;cursor:pointer;background:${
                  firstActive ? '#B8B9B9' : '#0B5087'
                }`"
                @click="firstActive = false"
                class="rounded-circle"
              />
            </div>
          </div>
        </div>
        <div class="col-lg p-4">
          <img src="@/static/T1.png" class="w-100" style="height:500px;object-fit:contain" v-if="firstActive" />
          <img src="@/static/T2.png" class="w-100" style="height:500px;object-fit:contain" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    options: function(){
        if(this.firstActive) return this.firstOption
        else return this.secondOption
    }
  },
  data() {
    return {
      firstActive: true, 
      firstOption: [
        "Leads",
        "Sales Pipeline",
        "Quotation",
        "Sales Order",
        "Vendor Management",
        "Purchase Request",
        "Request For Quotation",
        "Purchase Order",
        "Tender Management",
        "Inbound",
        "Storage",
        "Stock Transfer",
        "Outbound",
        "Re Order Point",
      ],
      secondOption: [
        'Vendor Bill',
        'Account Payable',
        'Invoices', 'Account Receiveable', 'Expenses', 'Tax In', 'Tax Out', 'Account Report',
        'Sales Performance', 'Branch Performance', 'Sales Communication', 'Service Excelence', 
        'Schedule Management', 'Task Management', 'Online Training'
      ],
    };
  },
};
</script>
